import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Modal, Nav, Row, Spinner, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useKeycloak } from 'keycloak-react-web';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendGetFile, useSendPost, useSendPut } from '../Server';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import '../index.scss';
import Assinatura from './Assinatura';

function PrescricaoFinalizada() {
  const { id } = useParams();
  const keycloak = useKeycloak();

  const navigate = useNavigate();
  const userData = keycloak.keycloak.idTokenParsed;

  let { state } = useLocation();
  if (state == null) {
    state = {

    }
  }
  const [dados, setDados] = useState<any>(state);
  const [opcoes, setOpcoes] = useState<any>({
    imprimirPrescricao: false,
    enviarSmsWhatsapp: false,
    imprimirDuasViasReceituarioEspecial: false,
  });

  const [outrasOpcoes, setOutrasOpcoes] = useState<any>({
    atualizar_telefone_paciente: true
  });



  const [status, setStatus] = useState<any>(null);

  const [mostrarReceituarioEspecial, setMostrarReceituarioEspecial] = useState<any>(null);
  const [mostraAtualizaTelefone, setMostraAtualizaTelefone] = useState<any>(null);
  const [selected, setSelected] = useState("dados");

  const sendPost = useSendPost();

  const sendGet = useSendGet();

  useEffect(() => {
    if (!state?.id) {
      sendGet("prescricao/verificacao/" + id,
        (response: any) => {
          setDados(response.data);
        });
    }
    sendGet("medico_opcoes",
      (response: any) => {
        setOpcoes(response.data);
      });
  }, []);

  useEffect(() => {
    imprimirChange();
  }, [dados, opcoes])

  function imprimirChange(): void {
    setMostrarReceituarioEspecial(opcoes.imprimirPrescricao
      && dados.receituarioEspecial);
  }

  function imprimirChangeEvent(e: ChangeEvent<HTMLInputElement>): void {
    const check = e.target.checked ? true : false;
    const p = { ...opcoes, imprimirPrescricao: check }
    setOpcoes(p);
  }

  function atualizaTelefoneEnvio(e: any): boolean {
    let d = { ...dados };
    d.pacienteObj.telefone = e;

    setDados(d);
    setMostraAtualizaTelefone(true);
    return true;
  }

  const [enviarDadosCall, setEnviarDadosCall] = useState(false);

  function enviarDados(): void {
    if (enviarDadosCall) return;
    setEnviarDadosCall(true);

    const d = {
      prescricao: id, opcoes: opcoes,
      telefoneEnvio: dados.pacienteObj.telefone,
      atualizarTelefonePaciente: outrasOpcoes.atualizar_telefone_paciente
    };

    sendPost("prescricao/enviar_gerar", d,
      (response: any) => {
        const r = response.data;
        navigate("/prescricao-visualizar/" + id, { state: { dados: dados, opcoes: opcoes } });

        setEnviarDadosCall(false);

      }, (error: any) => {
        setEnviarDadosCall(false);
        console.log(error);
        alert("Error ao gerar");
      });
  }

  const [showAssinatura, setShowAssinatura] = useState(false);
  const [tempoAssinatura, setTempoAssinatura] = useState<any>(null);

  const assinaturaRef = useRef(null);

  function switchAssinatura(e: any): void {
    if (e.target.checked)
      setShowAssinatura(true);
  }


  return <>
    <MenuPrincipal />
    <Container>
      <Row className="mt-5">
        <Col>
          <h2>Prescrição</h2>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={{ span: 3 }}>
          <Form.Check // prettier-ignore
            inline
            checked={opcoes.enviarSmsWhatsapp}
            type="switch"
            label="Enviar por SMS e Whatsapp"
            name='enviarSmsWhatsapp'
            onChange={(e) => setOpcoes({ ...opcoes, enviarSmsWhatsapp: e.target.checked ? true : false })}
          />
        </Col>
      </Row>
      {
        opcoes.enviarSmsWhatsapp ?
          <Row className="mt-3">
            <Form.Label>Informe o telefone para enviar pelo WhatsApp</Form.Label>
            <Col sm={{ span: 6 }}>
              <PhoneInputWithCountrySelect onChange={e => atualizaTelefoneEnvio(e)} defaultCountry='BR'
                value={dados.pacienteObj?.telefone} plaeholder="Digite o telefone" initialValueFormat='national' />
            </Col>
            {mostraAtualizaTelefone ?
              <Col sm={{ span: 3 }}>
                <Form.Check // prettier-ignore
                  checked={outrasOpcoes.atualizar_telefone_paciente}
                  inline
                  type="switch"
                  label="Atualizar telefone paciente"
                  id='atualizar_telefone_paciente'
                  onChange={(e) => setOutrasOpcoes({ ...outrasOpcoes, atualizar_telefone_paciente: e.target.checked ? true : false })}
                />
              </Col> : <></>
            }
          </Row>
          : <></>}

      <Row className="mt-3">
        <Col sm={{ span: 3 }}>
          <Form.Check // prettier-ignore
            checked={opcoes.imprimirPrescricao}
            inline
            type="switch"
            label="Imprimir"
            name='imprimir'
            onChange={(e) => imprimirChangeEvent(e)}
          />
        </Col>
      </Row>

      {mostrarReceituarioEspecial ?
        <Row className="mt-3">
          <Col sm={{ span: 3 }}>
            <Form.Check // prettier-ignore
              checked={opcoes.imprimirDuasViasReceituarioEspecial}
              inline
              type="switch"
              label="Imprimir duas vias no receituário especial"
              id='imprimirDuasViasReceituarioEspecial'
              onChange={(e) => setOpcoes({ ...opcoes, imprimirDuasViasReceituarioEspecial: e.target.checked ? true : false })}
            />
          </Col>
        </Row>
        : <></>
      }
      {opcoes.enviarSmsWhatsapp ?
        <>
          <Row className="mt-3">
            <Col>
              <Form.Check // prettier-ignore
                ref={(r: any) => assinaturaRef.current = r}
                inline
                type="switch"
                label={tempoAssinatura ? "Assinatura válida" : "Assinatura digital"}
                name='opcao'
                onChange={(e) => switchAssinatura(e)}
              />
            </Col>
          </Row>

        </> :
        <></>}



      <Row className="mt-5">
        <Col sm={{ span: 3 }}>
          <Button type='button' variant='primary' onClick={() => enviarDados()}>Avançar</Button>
        </Col>
      </Row>

      <Modal show={enviarDadosCall} aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Body>
          <Stack direction="horizontal" gap={3}>
            <Spinner className="float-left" animation="border" role="status" variant="primary" >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Gerando arquivo...</div>
          </Stack>
        </Modal.Body>
      </Modal>
    </Container>
    <MenuCelular />

    <Assinatura
      setValid={(v: boolean) => {
        var current: any = assinaturaRef.current;
        if (!current) return;
        current.checked = v;
      }}
      show={showAssinatura} handleClose={() => setShowAssinatura(false)} setTempoAssinatura={setTempoAssinatura} />
  </>
}
export default PrescricaoFinalizada;
//style={{ display: enviarDadosCall? 'block':'none'}}> 
