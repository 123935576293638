import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useState } from 'react';

function Pesquisa() {
    const [pesquisa, setPesquisa] = useState("");

    const data = [
        {
            title: "Card Title",
            subtitle: "Card Subtitle",
            text: "Some quick example text to build on the card title and make up thebulk of the card's content.",
            link: "Card Link",
        },
        {
            title: "Card Title",
            subtitle: "Card Subtitle",
            text: "Some quick example text to build on the card title and make up thebulk of the card's content.",
            link: "Card Link",
        },
        {
            title: "Card Title",
            subtitle: "Card Subtitle",
            text: "Some quick example text to build on the card title and make up thebulk of the card's content.",
            link: "Card Link",
        }
    ];

    return (
        <Container>
            <Row className='mt-3'>
                <Col>
                    <h1>Pesquisa medicamentos</h1>
                </Col>
            </Row>
            <Row>
                <Form>
                    <Form.Check // prettier-ignore
                        inline
                        type="radio"
                        label="Check this switch"
                        name='opcao'
                    />
                    <Form.Check // prettier-ignore
                        inline
                        type="radio"
                        label="Opcao de pesquisa"
                        id="disabled-custom-switch"
                        name='opcao'
                    />
                </Form>
            </Row>
            <Form.Group className="mt-4" controlId="formBasicEmail">
                <Row>
                    <Col xs={{ span: 9 }} md={{ span: 10 }}>
                        <Form.Control type="input" placeholder="Pesquisar medicamento, formula ou composto" value={pesquisa} onChange={e => setPesquisa(e.target.value)} />
                    </Col>
                    <Col xs={{ span: 2 }} md={{ span: 2 }}>
                        <Button type="button" >Pesquisar</Button>
                    </Col>
                </Row>
            </Form.Group>
            <Row>
                {
                    data.map((item) => (
                        <Col xs={{ span: 6 }} md={{ span: 4 }} lg={{ span: 3 }}>
                            <Card className='mt-3'>
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{item.subtitle}</Card.Subtitle>
                                    <Card.Text>{item.text}</Card.Text>
                                    <Card.Link href="#">{item.link}</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    );
}
export default Pesquisa;