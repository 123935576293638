export const listaEstados  = [
    { id: 1, nome: "Acre" },
    { id: 2, nome: "Alagoas" },
    { id: 3, nome: "Amapá" },
    { id: 4, nome: "Amazonas" },
    { id: 5, nome: "Bahia" },
    { id: 6, nome: "Ceará" },
    { id: 7, nome: "Distrito Federal" },
    { id: 8, nome: "Espírito Santo" },
    { id: 9, nome: "Goiás" },
    { id: 10, nome: "Maranhão" },
    { id: 11, nome: "Mato Grosso" },
    { id: 12, nome: "Mato Grosso do Sul" },
    { id: 13, nome: "Minas Gerais" },
    { id: 14, nome: "Pará" },
    { id: 15, nome: "Paraíba" },
    { id: 16, nome: "Paraná" },
    { id: 17, nome: "Pernambuco" },
    { id: 18, nome: "Piauí" },
    { id: 19, nome: "Rio de Janeiro" },
    { id: 20, nome: "Rio Grande do Norte" },
    { id: 21, nome: "Rio Grande do Sul" },
    { id: 22, nome: "Rondônia" },
    { id: 23, nome: "Roraima" },
    { id: 24, nome: "Santa Catarina" },
    { id: 25, nome: "São Paulo" },
    { id: 26, nome: "Sergipe" },
    { id: 27, nome: "Tocantins" }
  ];

export const listaSexo  = [
    { id: '1', nome: 'Feminino' },
    { id: '2', nome: 'Masculino' },
  ];

export const listaRaca  = [
    { id: '1', nome: 'Branco' },
    { id: '2', nome: 'Preto' },
    { id: '3', nome: 'Amarelo' },
    { id: '4', nome: 'Pardo' },
    { id: '5', nome: 'Indígena' },
    { id: '6', nome: 'Não declaradoo' },
  ];

export const listaCidades  = [
    { id: '1', nome: 'GOIÂNIA' },
    { id: '2', nome: 'APARECIDA DE GOIANIA' },
    { id: '3', nome: 'ANAPOLIS' },
    { id: '4', nome: 'TRINDADE' },
    { id: '5', nome: 'SENADOR CANEDO' },
    { id: '6', nome: 'HIDROLANDIA' }
  ];
  
  
  

export const listaAlergias  = [
    { id: 1, nome: 'Glutem' },
    { id: 2, nome: 'Crostácios' },
    { id: 3, nome: 'Lacteos' },
    { id: 4, nome: 'Amendoins' },
    { id: 5, nome: 'Pescado' },
    { id: 6, nome: 'Frutos Secos' },
    { id: 7, nome: 'Ovos' },
    { id: 8, nome: 'Moluscúlos' },
    { id: 9, nome: 'Mostarda' },
    { id: 10, nome: 'Sementes de Sésamo' },
    { id: 11, nome: 'Dioxido Enxofre e Sulfitos' },
];
