import { useState } from "react";
import { Alert } from "react-bootstrap";

export function useAlerta(): [showAlerta:()=>JSX.Element, setAlerta:Function]{
	const [alerta, setAlertaV] = useState<any>(null);
  const setAlerta = (v:any) => {
    setAlertaV(v);
    setTimeout(() => setAlertaV(null), 10000);
  }

  const showAlerta = ():JSX.Element => {
    return alerta != null ? <Alert variant={alerta.type}>{alerta.message}</Alert>:<></>
  }
  return [showAlerta, setAlerta];
}
