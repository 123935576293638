export function vazio(value: any) {
  return !value || value == null || 
    (value instanceof String && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0) ||
    (value instanceof Object && Object.keys(value).length === 0 )
}

export function vazioProps(value:any, props:any[]){
  if(vazio(value)) return props;
  return props.filter((p:any) => !(p in value) || vazio(value[p]));
}
