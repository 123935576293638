import { Card, Col,  Row } from 'react-bootstrap';
import { useCallback, useEffect, useRef, useState } from 'react';
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

function ResultadoMedicamento({ filtered, searchTerm, selecaoMedicamento, widthPesquisa }:
	{ filtered: any[], searchTerm: string, selecaoMedicamento: Function, widthPesquisa: Function }) {

	const [coluna1, setColuna1] = useState({ coluna1: null, coluna2: null })
	const [coluna2, setColuna2] = useState({ coluna2: null })
	const [coluna3, setColuna3] = useState({ coluna3: null })

	const reset = useCallback(() => {
		scrollToZero(scroll1);
		scrollToZero(scroll2);
		scrollToZero(scroll3);

		setColuna1({ coluna1: null, coluna2: null })
		setColuna2({ coluna2: null })
		setColuna3({ coluna3: null })
	}, [])

	useEffect(() => {
		if (filtered === null || filtered.length === 0)
			reset();
	}, [filtered, reset]);

	function updateColuna1(m: any, e: any) {
		if (!m) return;
		setColuna1(m);
		setColuna2({ coluna2: null });
		setColuna3({ coluna3: null });

		scrollToTop(scroll1, e);
		scrollToZero(scroll2);

	}

	function updateColuna2(m: any, e: any) {
		if (!m) return;
		setColuna2(m);
		setColuna3({ coluna3: null });

		scrollToTop(scroll2, e);
		scrollToZero(scroll3);
	}

	function updateColuna3(m: any, e: any) {
		if (!m) return;
		selecaoMedicamento(null, m);
		reset();
	}


	function scrollToZero(ref: any) {
		if (!ref.current || !ref.current.osInstance()) return;
		const viewport = ref.current.osInstance().elements().viewport;
		viewport.scrollTo({ top: 0 });
	}

	function scrollToTop(ref: any, e: any) {
		if (!ref.current) return;
		if (e.target.nodeName.toLowerCase() === 'p') {
			const viewport = ref.current.osInstance().elements().viewport;
			viewport.scrollTo({ top: e.target.parentElement.offsetTop, behavior: 'smooth' });
		}
		if (e.target.nodeName.toLowerCase() === 'li') {
			const viewport = ref.current.osInstance().elements().viewport;
			viewport.scrollTo({ top: e.target.offsetTop, behavior: 'smooth' });
		}
	}


	const termos = searchTerm.toLowerCase().split(" ");

	function sortColuna1(a: any, b: any) {
		let st = termos[0];
		let ac = a.coluna1;
		let bc = b.coluna1;

		let ai = ac.indexOf(st);
		let bi = bc.indexOf(st);

		return ai === 0 && bi === 0 ?
			(ac.length === bc.length ? ac.localeCompare(bc) : ac.length < bc.length ? -1 : 1) :
			ac.indexOf(st) === 0 ? -1 :
				bc.indexOf(st) === 0 ? 1 :
					ac.localeCompare(bc);
	}

	const fonteNormal = {
		fontSize: 15,
		margin: 0,
		padding: 0
	};

	const fonteNormalNegrito = {
		fontSize: 15,
		margin: 0,
		padding: 0,
		fontWeight: 'bold'
	};

	const fonteMenor = {
		fontSize: 13,
		margin: 5,
		padding: 0,
		color: '#939394',
		marginTop: -17 // Ajuste para diminuir a distância entre as linhas
	};


	function conteudoColuna1(m: any): any {
		return (
			<div className="lista-item">
				<p>
					<span style={fonteNormalNegrito}>{m.nome} {m.complemento}</span>
					<span style={fonteNormal}>{", " + m.tipo_apresentacao}</span>
				</p>
				<p style={fonteMenor}>{m.composicao}</p>
			</div>
		);
	}

	function conteudoColuna2(m: any): any {
		return (
			<div className="lista-item">
				<p style={fonteNormal}>{m.conteudo}</p>
			</div>
		)
	}


	function conteudoColuna3(m: any): any {
		return (
			<div className="lista-item">
				<p style={fonteNormal}><span>{m.laboratorio}</span>
					<span style={{ float: 'right' }} className="medicamentoPreco">{"R$ " + m.preco_sem_impostos}</span>
				</p>
			</div>
		)
	}


	const scroll1 = useRef<any>();
	const scroll2 = useRef<any>();
	const scroll3 = useRef<any>();

	return <>
		<Card style={{ width: widthPesquisa(), border: "1px solid #E8F2FE" }}>
			<Card.Body>
				<Row>
					<Col md={{ span: 7 }} style={{ borderRight: "1px solid #E8F2FE", borderBottom: "1px solid #E8F2FE" }}>
						<OverlayScrollbarsComponent ref={scroll1} defer style={{ height: 400 }}>
							<ul className="medicamento-grupos">
								{filtered == null ? "" :
									filtered
										.sort((a, b) => sortColuna1(a, b))
										.reduce((p, c) => p.length === 0 ? [c] : p[p.length - 1].coluna1 === c.coluna1 ? p : [...p, c], [])
										.map((m: any) => coluna1.coluna1 === m.coluna1 ?
											<li
												style={{
													color: '#000', // Texto Azul
													backgroundColor: '#E8F2FE', // Fundo Azul Claro
												}}
											>
												{conteudoColuna1(m)}
											</li> :
											<li style={{ color: coluna1.coluna1 != null ? '#888' : '#000' }} onClick={(e) => updateColuna1(m, e)}>
												{conteudoColuna1(m)}
											</li>
										)}
							</ul>
						</OverlayScrollbarsComponent>
					</Col>

					<Col md={{ span: 3 }} style={{ borderRight: "1px solid #E8F2FE", borderBottom: "1px solid #E8F2FE" }}>
						<OverlayScrollbarsComponent ref={scroll2} defer className="custom-scrollbar" style={{ height: 400 }}>
							<ul className="medicamento-grupos">
								{filtered == null ?
									<div className="text-secondary" style={{ marginTop: "40%", textAlign: "center" }}>Selecione um filtro</div>
									: filtered
										.sort((a, b) => a.coluna2.localeCompare(b.coluna2))
										.filter((m: any) => coluna1.coluna1 === m.coluna1)
										.reduce((p: any, c: any) => p.length === 0 ? [c] : p[p.length - 1].coluna2 === c.coluna2 ? p : [...p, c], [])
										.map((m: any) => m.coluna2 === coluna2.coluna2 ?
											<li style={{ color: '#000', backgroundColor: '#E8F2FE' }}>{conteudoColuna2(m)}</li> :
											<li style={{ color: coluna2.coluna2 != null ? '#888' : '#000' }} onClick={(e) => updateColuna2(m, e)}>{conteudoColuna2(m)}</li>)
								}
							</ul>
						</OverlayScrollbarsComponent>
					</Col>
					<Col md={{ span: 2 }} style={{ borderRight: "none", borderBottom: "1px solid #E8F2FE" }}>
						<OverlayScrollbarsComponent ref={scroll3} defer className="custom-scrollbar" style={{ height: 400 }}>
							<ul className="medicamento-grupos">
								{filtered == null ?
									<div className="text-secondary" style={{ marginTop: "40%", textAlign: "center" }}>Selecione um filtro</div>
									: filtered
										.filter((m) =>
											coluna1.coluna1 === m.coluna1 &&
											coluna2.coluna2 === m.coluna2)
										.reduce((p, c) => p.length === 0 ? [c] : p[p.length - 1].coluna3 === c.coluna3 ? p : [...p, c], [])
										.map((m: any) => m.coluna3 === coluna3.coluna3 ?
											<li className="lista-item-container" style={{ color: '#000', backgroundColor: '#E8F2FE', overflow: 'auto' }}>{conteudoColuna3(m)}</li> :
											<li className="lista-item-container" style={{ color: coluna3.coluna3 != null ? '#888' : '#000' }} onClick={(e) => updateColuna3(m, e)}>{conteudoColuna3(m)}</li>)
								}
							</ul>
						</OverlayScrollbarsComponent>
					</Col>
				</Row>
			</Card.Body>
		</Card>

	</>
}
export default ResultadoMedicamento;