import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { Accordion, Alert, Button, Card, Col, ListGroup, Modal, Nav, Row } from 'react-bootstrap';
import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import MenuCelular from './MenuCelular';
import PrescricaoPaciente from './PrescricaoPaciente';
import medicamentos from './Dados';
import { ChevronLeft } from 'react-bootstrap-icons';
import MenuPrescricao from './MenuPrescricao';
import Pesquisa from '../componentes/Pesquisa';
import { useSendPost, useSendPut } from '../Server';
import { useNavigate } from 'react-router-dom';
import 'overlayscrollbars/overlayscrollbars.css';
import StringIndex from '../componentes/StringIndex';
import { useLocalCache } from '../componentes/LocalCache';
import CondicoesAlergias from './prescricao/CondicoesAlergias';
import State from './prescricao/State';
import ResultadoMedicamento from './prescricao/ResultadoMedicamento';
import { vazio, vazioProps } from '../Validacao';
import { useAlerta } from '../componentes/Alerta';

const prescricoesRecentes = [
	{ id: 1, nome: 'JORGE DA SILVA', dataEmissao: '01/04/2024', itensPrescritos: 'Dipirona' },
	{ id: 3, nome: 'ANDREIA ALVES', dataEmissao: '01/04/2024', itensPrescritos: 'Azitromicina' },
	{ id: 2, nome: 'JOAO PIRES', dataEmissao: '30/03/2024', itensPrescritos: 'Loratadina, Diproiponato de clobetasol' },
	{ id: 4, nome: 'JAQUELINE FARIA', dataEmissao: '29/03/2024', itensPrescritos: 'Buscopan' },
];


function Prescricao() {

	const navigate = useNavigate();

	const [medicamentosList, setMedicamentosList] = useState(StringIndex(medicamentos));

	const [pacientes, setPacientes] = useState([]);
	const [pesquisaMedicamento, setPesquisaMedicamento] = useState("");
	const [filteredMedicamento, setFilteredMedicamento] = useState<any[]>([]);

	const [stateData, setStateValue] = useState([State.INICIAL, null]);
  
	
	const [itensPrescricao, setItensPrescricao] = useState<any[]>([]);

  const refHeight = useRef<any>();

	const refWidthPesquisa = useRef<any>();

	const [dados, setDados] = useState<any>({paciente: {},   data: new Date().toISOString().split('T')[0] });

	const state = stateData[0];
	const lastState = stateData[1];
  
  const [MensagemAlerta, setAlerta] = useAlerta();

	const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
		element.style.height = 'auto';
		element.style.height = `${element.scrollHeight}px`;
	};

	useEffect(() => {
		const textareas = document.querySelectorAll('.textarea-custom');
		textareas.forEach((textarea) => adjustTextareaHeight(textarea as HTMLTextAreaElement));
	}, [itensPrescricao]);

	useEffect(() => {
		if (!pesquisaMedicamento || "" === pesquisaMedicamento || pesquisaMedicamento.length < 3) {
			setFilteredMedicamento([]);
			return;
		}

		let termos = pesquisaMedicamento.toLowerCase().split(" ");

		let f = medicamentosList.filter((m: any) =>
			termos.reduce((p, v) => p >= 0 ?
				[1, 2, 3, 4].reduce((pv, cv) => pv < 0 ? m[cv].toLowerCase().indexOf(v) : pv, -1) : p, 1) > -1);


		const r = f.map((i: any) => {
			return {
				coluna1: (i[1] + " " + i[2] + " " + i[3]).toLowerCase(),
				coluna2: (i[5] + " " + i[6] + " " + i[7] + " " + i[8]).toLowerCase(),
				coluna3: (i[9] + " " + i[10]).toLowerCase(),
				id: i[0],
				nome: i[1],
				complemento: i[2],
				tipo_apresentacao: i[3],
				composicao: i[4],
				quantidade_apresentacao: i[5],
				unidade_apresentacao: i[6],
				quantidade_volume: i[7],
				unidade_volume: i[8],
				uso: i[9],
				laboratorio: i[10],
				preco_sem_impostos: i[11],
				conteudo: i[12],
				tipo_receituario: i[13]
			};
		});

		setFilteredMedicamento(r);
	}, [pesquisaMedicamento]);


	const pacientesDados = useLocalCache("pacientes", "paciente/medico", setPacientes);

	useEffect(() => {
		pacientesDados();
	}, []);


	const sendPost = useSendPost();
	const sendPut = useSendPut();

	var salvar = false;
	function salvarPrescricao() {

		if (salvar) return;
    salvar = true;
   
    const validaSalvarPrescricao = () => {
      let msg = null; 
      if(msg == null && vazio(dados.paciente))
        msg = {type:'warning', message: 'Infome os dados do paciente'};
      
      if(msg == null && vazio(itensPrescricao))
        msg = {type:'warning', message: 'Inclua pelo menos um item na prescricao'};

      if(msg){
        setAlerta(msg);
        return false;
      }
      return true; 
    };
    
    if(!validaSalvarPrescricao()){
      salvar = false;
      return;
    }

		const savePresc = () => {
			sendPost("prescricao/save",
				{
					paciente: dados.paciente.paciente,
					data: dados.data,
					itens: itensPrescricao.map((k: any, i: number) => {
						return {
							medicamento: k.id,
							posologia: k.posologia,
							quantidade: 1,
							sequencial: (i + 1),
						}
					})
				},
				(response: any) => {
					setDados({ ...dados, id: response.data.prescricao });
					setState(State.gerar_prescricao);
				}, 
        (error: any) => {
          setAlerta({type:'danger', message: error.response.data});
          salvar = false;
        });
		}
		
		const path = dados.paciente?.paciente ?	
			"paciente/update/prescricao/" + dados.paciente.paciente:
			"paciente/create/prescricao";

			sendPost(path, dados.paciente,
				(response: any) => {
					console.log(response);
					dados.paciente = response.data;
					savePresc();
					salvar = false;
				}, (error: any) => {
          setAlerta({type:'danger', message: error.response.data});
          salvar = false;
				});
	}


	function setState(s: State) {
		setStateValue([s, ...stateData]);
	}

	function popSate() {
		setStateValue(stateData.slice(1));
	}

	useEffect(() => {
		if (isStateChange(State.INICIAL, State.incluindo_prescricao)) {
			refWidthPesquisa.current?.focus();
		}
		else if (state === State.gerar_prescricao) {
			navigate("/prescricao-verificacao/" + dados.id);
		}
		//eslint-disable-next-line
	}, [stateData]);

	function isStateChange(from: State, to: State) {
		return lastState === from && state === to;
	}

	function iniciarIncluirPrescricao() {
		setState(State.incluindo_prescricao);
	}

	const [id, setId] = useState<number>(0);

	function nextId() {
		setId(id + 1);
		return id + 1;
	}

	function incluirPrescricao(_: any, p: any): void {
		setItensPrescricao([...itensPrescricao, { id: nextId(), posologia: "", ...p }]);
		setPesquisaMedicamento("");
		setState(State.incluso_prescricao);
	}

	function removerItemPrescricao(p: any, index:number): void {
		let nova = [...itensPrescricao]; 
 
		nova.splice(index, 1);
		
		setItensPrescricao(nova);;
	}


	function itemPrescricaoOnChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, p: any, prop: string): void {
		var item = { ...p };
		item[prop] = e.target.value;

		var nova = itensPrescricao.map((i) => i.id === p.id ? item : i);
		setItensPrescricao(nova);
	}

	function incluirDadosPaciente(): ReactNode {
		if (state !== State.incluir_alergia && 
			state !== State.incluir_condicao && 
			state !== State.incluir_paciente 
		) {
			return <></>
		}

		var def = (state === State.incluir_alergia ? "alergia" :
				state === State.incluir_condicao ? "condicao" :
			 "info");
		return <PrescricaoPaciente setPaciente={(p:any) =>
			 { console.log(p);setDados({ ...dados, paciente: p })}} paciente={dados.paciente} onHide={() => popSate()} defaultView={def} />
	}

	function mostarFormularioPrescricao(): boolean | undefined {
		return (state !== State.incluir_alergia && 
			state !== State.incluir_condicao && 
			state !== State.incluir_paciente );
	}
  
  function calcularIdade(data:Date):any{
    const day = 1000 * 60 * 60 * 24;
    const year = day * 365.25;
    const month = day * 30.5;

    let today = new Date().getTime();
    today -= today % day;
    let dif = today - data.getTime();
    
    let years = Math.floor(dif / year);
    let months = Math.floor((dif - years * year) / month); 
    let days = Math.floor((dif - years * year - months * month) / day); 

    return {anos:years, meses:months, dias:days};
  }
  
  function descricaoIdade(idade:any){
    if(idade.anos < 1){
      return idade.meses + " meses e " + idade.dias + " dias";
    }
    else if(idade.anos < 2){
      return idade.anos + " ano ," + idade.meses + " meses e " + idade.dias + " dias";
    }
    else {
      return idade.anos + " anos e " + idade.meses + " meses";
    }
  }

	return (
		<div style={{height: 600 + refHeight.current?.offsetHeight}} className='bg-secondary' >
			{incluirDadosPaciente()}
      {
        <MenuPrescricao gerarPrescricaoFn={salvarPrescricao} />
      }
			{/* 
			<Modal
				show={mostarFormularioPrescricao()}
				size='xl'
				fullscreen={"xl-down"}
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Body>
      */}
			<Container  fluid='sm' className='mh-100' ref={refHeight} >
				<Row  className="mt-5">
					<Col xxl={{ span: 2 }}></Col>
					<Col ref={refWidthPesquisa} className="border  p-4 mh-100 bg-body" >
        <MensagemAlerta />
				<Row className="justify-content-end mb-2 ">
					<Col xs={{ span: 4 }} style={{ textAlign: 'right' }}>
						<Nav.Link href="/dashboard"><ChevronLeft /> Voltar</Nav.Link>
					</Col>
				</Row>
				<Row className="justify-content-end">
					<Col md={{ span: 4 }}>
						<Stack gap={2} direction="horizontal">
							<h6>Data</h6>
							<Form.Control type="date" value={dados.data} onChange={(p: any) => setDados({ ...dados, data: p.target.value })} />
						</Stack>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<Pesquisa selecao={dados.paciente} label="Nome ou CPF" placeholder='Buscar pelo nome ou CPF' itens={pacientes}
							setSelecao={(p: any) => {
								setDados({ ...dados, paciente: p })
								console.log(p);
							}}
							filterFn={(p: any, pesq: any) => (p.nome.toLowerCase().indexOf(pesq.toLowerCase()) > -1 || p.cpf === pesq)} 
							notFoundContent={(p:any) => p ? <ListGroup.Item action onClick={() => {

								setState(State.incluir_paciente);
								setDados({ ...dados, paciente: {nome: p}});

							}}>Criar '{p}'</ListGroup.Item>: ''}/>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col sm={{span: 2}}>
							<span>Peso (kg)</span>
							<Form.Control type="input" value={dados.paciente?.peso} onChange={(p: any) => setDados({ ...dados, paciente: { ...dados.paciente, peso: p.target.value }})} />
					</Col>
					<Col sm={{span: 3}}>
							<h6>Data Nascimento</h6>
							<Form.Control type="date" value={dados.paciente?.dataNascimento} onChange={(p: any) => setDados({ ...dados, paciente: { ...dados.paciente, dataNascimento: p.target.value }})} />
					</Col>
					<Col sm={{span: 3}}>
							<h6>Idade</h6>
              <p>{ dados.paciente?.dataNascimento ? descricaoIdade(calcularIdade(new Date(dados.paciente.dataNascimento))): null}</p>
					</Col>
					<Col sm={{span: 4}} className='float-right'>
              <Button variant="link" onClick={() => setState(State.incluir_paciente)}>Outras Informaçoes...</Button>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
            <Stack direction="horizontal" gap={2}  className='float-right'>
                {dados.paciente?.gravidez ? <Button variant="warning" onClick={() => setState(State.incluir_condicao)}>Gestante</Button> : ''}
                {dados.paciente?.lactacao ? <Button variant="warning" onClick={() => setState(State.incluir_condicao)} >Lactação</Button> : ''}
                {dados.paciente?.alergias?.length ? <Button variant="warning"  onClick={() => setState(State.incluir_alergia)}>
                  {"Alergia a " + dados.paciente.alergias.map((a: any) => a.nome).join(', ')}</Button> : ''}
            </Stack>
					</Col>
        </Row>

				<Row key="itensPrescricao" className="border-top mt-3" lista-item-container>
					{itensPrescricao.map(
						(p, index) =>
							<Col key={p.id} sm={{ span: 12 }} className="border-bottom pb-3 mt-3">
								<Row>
									<Col key={p.id} sm={{ span: 12 }} className="border-bottom pb-3 mt-3">
										<Row>
											<Col xs={{ span: 7 }}>
												<div className="texto-nome-medicacao">
													<span className="bold">{p.nome + " " + p.complemento + ", "}</span> {p.tipo_apresentacao} {p.tipo_receituario}, {p.laboratorio}
													{/*<span className="bold">{p.nome + ", "}</span> {p.tipo_apresentacao}, {p.laboratorio} */}
												</div>
												<Form.Control
													as="textarea"
													rows={1}
													value={p.composicao}
													className="custom-select texto-composicao border-0 mt-1 textarea-custom"
													readOnly
													plaintext
													style={{ marginLeft: '7px' }}
												/>
												<Form.Control
													as="textarea"
													rows={1}
													value={"Embalagem com " + p.conteudo}
													className="custom-select texto-conteudo border-0 mt-1 textarea-custom"
													readOnly
													plaintext
													style={{ marginLeft: '7px' }}
												/>
												<Form.Control
													type="input"
													value={p.posologia}
													placeholder="Digite aqui a posologia"
													onChange={(e) => itemPrescricaoOnChange(e, p, 'posologia')}
													className="custom-select border-0 mt-1 textarea-custom placeholder-blue"
													list='pesquisa-medicamento'

												/>
											</Col>
											<Col xs={{ span: 3 }}>
												<Form.Control
													as="textarea"
													rows={1}
													value={p.quantidade_apresentacao + " " + p.unidade_apresentacao}
													className="custom-select border-0 textarea-custom"
													readOnly
													plaintext
													style={{ textAlign: 'right' }}
												/>
											</Col>
											<Col xs={{ span: 2 }}>
												<Button variant="outline-primary" className="mutted" onClick={() => removerItemPrescricao(p, index)}>
													Remover
												</Button>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
					)}
				</Row>

				<Row style={{ display: mostrarCampoPrescricao() ? "block" : "none" }} className="mt-3">
					<Col>
						<Stack gap={2} direction="horizontal">
							<Form.Control type="input" value={pesquisaMedicamento} onChange={e => setPesquisaMedicamento(e.target.value)}
								placeholder='Digite aqui para buscar itens'
								className='custom-select placeholder-blue border-0' list='pesquisa-medicamento' />
						</Stack>

						<Col sm={{ span: 12 }} md={{ span: 4 }} className="position-absolute"
							style={{ marginLeft: 20, marginTop: -1, zIndex: 1000, minWidth: 700, display: (filteredMedicamento.length === 0 ? 'none' : 'block') }}>
							<ResultadoMedicamento filtered={filteredMedicamento} searchTerm={pesquisaMedicamento} 
                selecaoMedicamento={incluirPrescricao} widthPesquisa={() => refWidthPesquisa.current?.offsetWidth} />
						</Col>
					</Col>
				</Row>
				<Stack gap={2} className="mt-3 col-md-8 mx-auto">
					<Button variant="primary" onClick={() => iniciarIncluirPrescricao()}>Adicionar à prescrição</Button>
				</Stack>
				{ultimasPrescricoes()}
					</Col>
					<Col xxl={{ span: 2 }}></Col>
				</Row>	
			</Container>
			<MenuCelular />
		</div>
	);

	function mostrarPrecscricoesRecentes(): boolean {
		return state == State.INICIAL && prescricoesRecentes && prescricoesRecentes.length > 0;
	}

	function ultimasPrescricoes(): JSX.Element {
		return <>
		{mostrarPrecscricoesRecentes()?
		<Accordion className='mt-5'>
			<Accordion.Item eventKey='0'>
				<Accordion.Header> Ultimas prescrições </Accordion.Header>
				<Accordion.Body>
				
		<Row>
		{prescricoesRecentes.filter((_, idx, arr) => idx < arr.length - arr.length % 2)
			.map(r => <Col className="mt-3 h-100" key={r.id} xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 3 }}>
			<h6 className='text-primary'>{r.nome}</h6>
			<p>{r.itensPrescritos} <br/>Emitida em {r.dataEmissao} 
			<br/>
			<a href="#">Abrir</a>
			</p>
		</Col>
		)}
				</Row>
				</Accordion.Body>	
			</Accordion.Item>
			</Accordion>: <></>}
		</>
	}

	function mostrarCampoPrescricao(): boolean {
		return state === State.incluindo_prescricao;
	}
}

export default Prescricao;
