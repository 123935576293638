import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Modal, Nav, Row, Spinner, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import Pesquisa from '../componentes/Pesquisa';
import { useKeycloak } from 'keycloak-react-web';
import { Link, redirect, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendGetFile, useSendPost, useSendPostFile, useSendPut } from '../Server';
import Config from './Config';
import { url } from 'inspector';
import DocumentViewer from '../componentes/DocumentViewer';
import printJS from 'print-js';

function PrescricaoVisualizacao() {
  const { id } = useParams();

  let { state } = useLocation();
  if (state == null) {
    state = {dados: {}, opcoes: {}}
  }

  const navigate = useNavigate();
  const sendGetFile = useSendGetFile();

  const [message, setMessage] = useState<any>(null);
  
  useEffect(() => {
    if(state.opcoes.imprimirPrescricao){
      setTimeout(() =>{
        imprimirPrescricao(); 
      }, 100)
    }
  }, []);

  let download = false;
  function downloadPrescricao() {
    if(download) return;
    download = true;
    setMessage("Salvando prescricao...");
    sendGetFile("prescricao/download_prescricao/" + id, (response: any) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'prescricao' + id + '.pdf');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      download = false;
      setMessage(null);
    });
  }

  let printing = false;
  function imprimirPrescricao(): void {
    if (printing) return;
      
      printing = true;
      setMessage("Imprimindo...");
      sendGetFile("prescricao/download_prescricao/" + id, (response: any) => {
        const href = URL.createObjectURL(response.data);
        printJS(href);
        URL.revokeObjectURL(href);
        printing = false;
        setMessage(null);
      })
  };

  return <>
    <MenuPrincipal apenasLogo={true} logoLink="/" />
    <Container>
      <Row className="mt-5">
        <Col>
          <h2>Prescricão</h2>
        </Col>
        <p>O arquivo da prescrição pode ser visualizado abaixo</p>
      </Row>
      <Row className="mt-3">
        <Col>
          <DocumentViewer url={Config.get().url("prescricao/visualizacao/" + id)} />
        </Col>
      </Row>
      <Form>
        <Row className="mt-3">
          <Col className="float-right">
            <Stack direction="horizontal" gap={3}>
              <Button className='ms-auto' type='button' variant='primary' onClick={() => downloadPrescricao()}>Salvar uma cópia</Button>
              <Button type='button' variant='primary' onClick={() => imprimirPrescricao()}>Imprimir</Button>
              <Button type='button' variant='primary' onClick={() => navigate("/prescricao")}>Fechar</Button>
            </Stack>
          </Col>
        </Row>
      </Form>
    </Container>
    <Modal show={message != null}  aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Body>
          <Stack direction="horizontal" gap={3}>
            <Spinner className="float-left" animation="border" role="status" variant="primary" >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>{message}</div>
          </Stack>
      </Modal.Body>
    </Modal>
  </>
}
export default PrescricaoVisualizacao;
