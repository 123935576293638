import { useEffect, useState } from "react";
import { Button, Col, ListGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { vazio, vazioProps } from "../Validacao";
import { useAlerta } from "../componentes/Alerta";
import { useLocalCache } from "../componentes/LocalCache";
import { verificaCpf } from "../componentes/Validacao";
import Lista from "../componentes/Lista";
import { listaAlergias } from "../componentes/DadosComuns";

function PrescricaoPaciente(
  {
    onHide,
    defaultView,
    paciente,
    setPaciente
  }:
    {
      onHide: any,
      defaultView: any
      paciente: any
      setPaciente: any
    }) {

  const [listaSexo, setListaSexo] = useState([
    { id: '1', nome: 'Feminino' },
    { id: '2', nome: 'Masculino' },
  ]);


  const [pacientes, setPacientes] = useState([]);
  const pacientesDados = useLocalCache("pacientes", "paciente/medico", setPacientes);

  useEffect(() => {
    pacientesDados();
  }, []);

  const [focus, setFocus] = useState<any>({});

  const updateFocus = (v: any) => {
    let d = { ...focus };
    d[v] = true;
    setFocus(d);
  }

  const [MensagemAlerta, setAlerta] = useAlerta();

  function finalizar(): void {
      let msg = null; 


      if(msg == null && !vazio(paciente.cpf)){
          let r = verificaCpf(paciente.cpf, paciente.paciente, pacientes, setAlerta);
          if(!r) return;
      }



      let props = vazioProps(paciente, ['nome', 'peso', 'sexo', 'dataNascimento'])
      if(msg == null && props.length > 0) 
        msg = {type:'warning', message: 'Informe dados do paciente: ' + props.join(', ')};
      

      if(msg != null){
        setAlerta(msg);
        return;
      }
      onHide();  
  }

  return <Modal
    show={true}
    size='xl'
    backdrop="static"
    fullscreen={"lg-down"}
    onHide={finalizar} >
    <Modal.Header closeButton>
      <Modal.Title>
        Paciente
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <MensagemAlerta />
      <Form>
        <Tabs
          defaultActiveKey={defaultView}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="info" title="Informações">
            <Form.Group className="mt-2">
              <Form.Label>Nome *</Form.Label>
              <Form.Control type="input" placeholder="Digite o nome do paciente"
                value={paciente.nome} onChange={(e) => setPaciente({ ...paciente, nome: e.target.value.toUpperCase() })} />
            </Form.Group>

            <Form.Group className="mt-4">
              <Form.Label>CPF</Form.Label>
            <InputMask className="form-control" mask="999.999.999-99" value={paciente.cpf} onChange={e => setPaciente({ ...paciente, cpf: e.target.value })}
              type="input" placeholder="Digite o cpf do seu paciente" aria-describedby="cpfdescription" id="cpf"
                onBlur={(e) => {
                  updateFocus(e.target.id);
                  verificaCpf(e.target.value, paciente.paciente, pacientes, setAlerta);
                }}
              />
              {
                focus.cpf && vazio(paciente.cpf) ?
                  <Form.Text id="cpfdescription" muted >
                    Esta informação pode ser importante para a identificação
                  </Form.Text> : null
              }

            </Form.Group>
            <Row className="mt-4">
              <Col>
                <Form.Group >
                  <Form.Label>Data Nascimento *</Form.Label>
                  <Form.Control type="date" placeholder="Digite a data de nascimento do paciente"
                    value={paciente.dataNascimento} onChange={(e) => setPaciente({ ...paciente, dataNascimento: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group >
                  <Form.Label>Peso(kg) *</Form.Label>
                  <Form.Control type="input" placeholder="Digite o peso do paciente"
                    value={paciente.peso} onChange={(e) => setPaciente({ ...paciente, peso: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Sexo * </Form.Label>
                  <Form.Select aria-label="Selecione o sexo do seu paciente"
                    id="sexo" onChange={e => setPaciente({ ...paciente, sexo: e.target.value })} >
                    <option value="">Selecione</option>
                    {listaSexo.map((i) => <option selected={paciente.sexo === i.id} value={i.id}>{i.nome}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="alergia" title="Alergias">
            <Lista pesquisaItens={listaAlergias} label='' placeholder='Selecione o alergenico' itens={paciente.alergias} setItens={(itens: any[]) => setPaciente({ ...paciente, alergias: itens })} />
          </Tab>
          <Tab eventKey="condicao" title="Condições" >
            <Row className="mt-3">
              <Col>
                <Form.Check type="checkbox" label="Gravidez"
                  checked={paciente.gravidez} onChange={(e) => setPaciente({ ...paciente, gravidez: e.target.checked })} />
                <Form.Check type="checkbox" label="Lactação"
                  checked={paciente.lactacao} onChange={(e) => setPaciente({ ...paciente, lactacao: e.target.checked })} />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Form>
      <Row className="mt-5">
        <Col>
          <Button onClick={() => finalizar()}>Ok</Button>
        </Col>
      </Row>
    </Modal.Body>
  </Modal>;
}
export default PrescricaoPaciente;
