export const usePropertySort = function(property: string) {
	return (a:any, b:any) => {
		return a[property].localeCompare(b[property]);
	}	
}

export const useSetSorted = function(property: string, setList:Function) {
	return (list: any[]) => {
		list.sort((a:any, b:any) => a[property].localeCompare(b[property]));
		setList(list);
	}
}

