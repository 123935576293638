import { useKeycloak } from "keycloak-react-web";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {

  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const [texto, setTexto] = useState("")

  useEffect(() => {
    if(initialized && keycloak.authenticated){
      setTexto("Saindo...")
      keycloak.logout();
    }
  }, [initialized]);

  
  useEffect(() => {
    setTimeout(() => {
        navigate("/");
    }, 500);
  }, []);

	
	return <>{texto}</>;
}

export default Logout;
