import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Dropdown, Nav, Row, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import MenuPrincipal from './MenuPrincipal';
import MenuCelular from './MenuCelular';
import { useNavigate, useParams } from 'react-router-dom';
import Config from './Config';
import axios from 'axios';
import { useKeycloak } from 'keycloak-react-web';


function PrescricaoGerar() {
  const {id} = useParams();
  const keycloak = useKeycloak();
  const [downloaded,setDownloaded] = useState(false)
 
  const url:any = Config.get().url("prescricao/pdf/" + id + "");
  console.log(url);

  useEffect(() => {
    if(!downloaded){
      downloadPrescricao();
      setDownloaded(true);
    }
  },[]);

  function downloadPrescricao(): void {
    console.log("Requesting url :" + url);
    axios.defaults.headers.common = { Authorization: 'Bearer ' + keycloak.keycloak.token };

    axios({url: url, method: 'GET', responseType: 'blob'})
      .then((response) => {
          console.log("Reading pdf response");
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'prescricao.pdf');
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
      });
  }

	return (
		<>
		</>
	);
}
export default PrescricaoGerar;
