import 'overlayscrollbars/styles/overlayscrollbars.css';
import './MedicamentoLista.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Button, Card, Col, FormSelect, ListGroup, Offcanvas, Row, Stack, Table } from 'react-bootstrap';
import { useState } from 'react';
import MenuPrincipal from './MenuPrincipal';
import MenuCelular from './MenuCelular';
import { useNavigate } from 'react-router-dom';
import { Search } from 'react-bootstrap-icons';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

function MedicamentoLista() {
  const [pesquisa, setPesquisa] = useState("");

  const navigate = useNavigate();

  const laboratorios = [
    "Nenhum",
    "1Drop",
    "1Farma",
    "3M",
  ];

  return (
    <>
      <Offcanvas show={true} placement="top" backdrop={false} scroll={true} style={{ height: 280 }}>
        <MenuPrincipal />
        <Container>
          <Row className="mb-3 mt-5">
            <Stack direction="horizontal" gap={2}>
              <span>Buscar por:</span>
              <Button style={{ height: "100%", paddingTop: 10, paddingBottom: 10 }} variant="primary">Nomes comerciais</Button>
              <Button style={{ height: "100%", paddingTop: 10, paddingBottom: 10 }} variant="outline-primary">Nomes genéricos</Button>
              <Button style={{ height: "100%", paddingTop: 10, paddingBottom: 10 }} variant="outline-primary">Formulas manipuladas</Button>
              <Button style={{ height: "100%", paddingTop: 10, paddingBottom: 10 }} variant="outline-primary">Favoritos</Button>
            </Stack>
          </Row>
          <Row>
            <Col>
              <Search style={{ position: "absolute", marginTop: 15, marginLeft: 10 }} />
              <Form.Control style={{ paddingLeft: 40, paddingTop: 10, paddingBottom: 10 }} type="input" placeholder="Pesquisar medicamento, formula ou composto" value={pesquisa} onChange={e => setPesquisa(e.target.value)} />
            </Col>
          </Row>
        </Container>
      </Offcanvas>
      <Row style={{ marginTop: 200, borderBottom: "1px solid #ccc" }}>
        <Col style={{ borderRight: "1px solid #ccc", overflowX: 'clip'}}>
          <h6 style={{ paddingTop: 89 }}>Laboratórios</h6>
        </Col>
        <Col>
          <h6 style={{ paddingTop: 89 }}>
            Classes farmacoterapêuticas</h6>
        </Col>
        <Col>
        </Col>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col style={{ borderRight: "1px solid #ccc" , borderBottom: "1px solid #ccc" }}>
          <OverlayScrollbarsComponent defer style={{height: 200}}>
            <ul className="medicamento-grupos">
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
            </ul>
          </OverlayScrollbarsComponent>
        </Col>

        <Col style={{ borderRight: "1px solid #ccc" , borderBottom: "1px solid #ccc" }}>
          <OverlayScrollbarsComponent defer style={{height: 200}}>
          <ul className="medicamento-grupos">
            <li>Cras justo odio</li>
            <li>Dapibus ac facilisis in</li>
            <li>Morbi leo risus</li>
            <li>Porta ac consectetur ac</li>
            <li>Vestibulum at eros</li>
            <li>Cras justo odio</li>
            <li>Dapibus ac facilisis in</li>
            <li>Morbi leo risus</li>
            <li>Porta ac consectetur ac</li>
            <li>Vestibulum at eros</li>
            <li>Cras justo odio</li>
            <li>Dapibus ac facilisis in</li>
            <li>Morbi leo risus</li>
            <li>Porta ac consectetur ac</li>
            <li>Vestibulum at eros</li>
          </ul>
          </OverlayScrollbarsComponent>
        </Col>
        <Col style={{ borderRight: "1px solid #ccc" , borderBottom: "1px solid #ccc" }}>
          <div className="text-secondary" style={{ marginTop: "50%" , textAlign: "center" }}>Selecione um filtro</div>
        </Col>
        <Col style={{ borderRight: "1px solid #ccc" , borderBottom: "1px solid #ccc" }}>
          <div className="text-secondary" style={{ marginTop: "50%", textAlign: "center" }}>Selecione um filtro</div>
        </Col>
      </Row>
      <Row className={"mt-4"}>
        <Col>
          <Table responsive="sm">

            <thead>
              <tr>
                <th>#</th>
                <th>Table heading</th>
                <th>Table heading</th>
                <th>Table heading</th>
                <th>Table heading</th>
                <th>Table heading</th>
                <th>Table heading</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>
            </tbody>
          </Table>
          <ul>
            <li>

            </li>
          </ul>
        </Col>
      </Row>
      <MenuCelular />
    </>
  );
}
export default MedicamentoLista;
