import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useKeycloak } from 'keycloak-react-web';
import { useNavigate } from 'react-router-dom';

function Login() {

  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if(initialized && !keycloak.authenticated){
      keycloak.login();
    }
    if(initialized && keycloak.authenticated){
      setLogin(true)
    }
  }, [initialized, keycloak]);
	

  useEffect(() => {
    setTimeout(() => {
        navigate("/");
    }, 1000);

    if(login)
      navigate("/");
  },[login, navigate])


	return (
    <></>
	);
}

export default Login;
