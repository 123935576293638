import Container from 'react-bootstrap/Container';
import { Nav} from 'react-bootstrap';
import MenuPrincipal from './prescrito/MenuPrincipal';

function AppIndex() {
	return (
		<Container>
      <MenuPrincipal apenasLogo={true} />
			<Nav defaultActiveKey="/dashboard" className="flex-column">
				<Nav.Link href="/home">Entrar</Nav.Link>
			</Nav>
		</Container>
	);
}

export default AppIndex;
