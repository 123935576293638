import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { useState } from 'react';
import Pesquisa from '../componentes/Pesquisa';


function Lista({ itens, setItens, label, placeholder, pesquisaItens }:
  { itens: any[], setItens: Function, label?: string, placeholder: string, pesquisaItens: any[] }) {

  itens = itens ? itens : [];
  const [selecao, setSelecao] = useState<any>({});

  function incluirItem() {
    setItens([...itens, selecao])
    setSelecao(null);
  }

  function removerItem(item: any) {
    setItens(itens.filter((i) => i != item))
  }


  return <>
    {label ?
      <h3>{label}</h3>
      : ""}
    <Row className='mt-3'>
      <Col xs={{ span: 8 }} md={{ span: 10 }}>
        <Pesquisa selecao={selecao} setSelecao={setSelecao} placeholder={placeholder} itens={pesquisaItens} />
      </Col>
      <Col xs={{ span: 4 }} md={{ span: 2 }}>
        <Button onClick={() => incluirItem()}>Incluir</Button>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <ListGroup className="justify-content-between">
          {itens.map((i) => <ListGroup.Item as="li" className="d-flex justify-content-between " >
            <div className="mt-1">{i.nome}</div>
            <Button variant="link" className="secondary" onClick={() => removerItem(i)}>Remover</Button>
          </ListGroup.Item>)}
        </ListGroup>
      </Col>
    </Row>
  </>
}
export default Lista;