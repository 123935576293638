import React from 'react';

const DocumentViewer = ({url}:{ url:string }) => {
  return (
    <iframe
      className="border"
      title="Document Viewer"
      src={`https://docs.google.com/viewer?embedded=true&url=${url}`}
      width="100%"
      height="600"
    />
  );
};

export default DocumentViewer;