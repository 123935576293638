import { NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';

function MenuCelular() {
    return (
        <Nav className='d-lg-none mt-5' justify defaultActiveKey="/home">
            <Nav.Item>
                <Nav.Link href="/home">Inicio</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/pacientes">Pacientes</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="link-1">Medicamentos</Nav.Link>
            </Nav.Item>
            <NavDropdown title="Mais ..." id="nav-dropdown">
                <NavDropdown.Item eventKey="prescricao-configuracao" 
                  href="/prescricao-configuracao">Modelos de Prescricão</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );
}
export default MenuCelular;
