import {Form, Button, Modal, Row } from "react-bootstrap";
import { useSendGet, useSendPost } from "../Server";
import { useEffect, useState } from "react";

function Assinatura({ show, setValid, handleClose, setTempoAssinatura }:
  { show: boolean, setValid: Function, handleClose: Function, setTempoAssinatura: Function }) {

  const [timeout, setMTimeout] = useState<any>(0);
  const [message, setMessage] = useState<any>();
  const [dados, setDados] = useState<any>({});
  const sendPost = useSendPost();
  const sendGet = useSendGet();
  const [showVidaas, setShowVidaas] = useState(false);
  
  const [tempoSessao, setTempoSessao] = useState([
    { id: 8, nome: "8 horas" },
    { id: 12, nome: "12 horas" },
    { id: 24, nome: "24 horas" },
    { id: 4, nome: "4 horas" },
    { id: 1, nome: "1 hora" }
  ]);

  var validando = false;

  useEffect(() => {
    if(validando) return;
    validando = true;

    setValid(false);
    const validar = () =>sendGet("vidaas_sessao/validar",
      (response: any) => {

        const data = response.data;
        const valido = data.status == "Valido"
        setValid(valido);

        if(valido) {
          setTempoAssinatura(data.validoPor);
          if(show && showVidaas) {
            setMessage(null)
            setShowVidaas(false);
            handleClose();
          }
          setTimeout(validar, 60000);
          console.log("valido");
        }
        else {
          if(message != null){
            setTimeout(validar, 1000);
            console.log("message");
          }
          else {
            setTimeout(validar, 60000);
            console.log("timeout");
          }
        }
        validando = false;
      });
      setTimeout(validar, 0);
    }, [timeout, message]);

  function iniciarAssinaturaVidas(): any {
    setMTimeout(1000);
    setValid(false);
    setShowVidaas(true);
    setMessage(null);
  }

  function processarAssinaturaVidas(): void {
    sendPost("vidaas_sessao/iniciar", dados, (response: any) => {
      if (response.data.url != null) {
        window.open(response.data.url, '_blank');
      }
    });
    setMessage("Confirmando assinatura. Verifique seu aplicativo Vidaas ...");
  }

  return <>
    <Modal show={show && !showVidaas} onHide={() => {setValid(false); handleClose()}}>
      <Modal.Header closeButton>
        <Modal.Title>Assinatura Digital</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button onClick={() => iniciarAssinaturaVidas()}>
          Assinar com Vidaas
        </Button>
      </Modal.Body>
    </Modal>
    <Modal show={show && showVidaas} onHide={() => setShowVidaas(false) }>
      <Modal.Header closeButton>
        <Modal.Title>Assinatura Vidaas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message?
          <p>{message}</p>
        :
         <>
          <Row>
          <Form.Group className="mb-3" >
            <Form.Label>Tempo sessão</Form.Label>
            <Form.Select id="tempoSessao" aria-label="Selecione o tempo de sessão"
              onChange={e => setDados({ ...dados, tempoSessao: e.target.value })}>
              {tempoSessao.map((e) => <option selected={e.id === dados.tempoSessao} value={e.id}>{e.nome}</option>)}
            </Form.Select>
          </Form.Group>
        </Row>
        <Button onClick={() => processarAssinaturaVidas()}>
          Ok
        </Button>
        </>
        }
      </Modal.Body>
    </Modal>
  </>

}

export default Assinatura;
