import Container from 'react-bootstrap/Container';
import { Nav} from 'react-bootstrap';
import { useEffect } from 'react';
import { useSendGet } from './Server';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from 'keycloak-react-web';

function Home() {
	const get = useSendGet();
	const navigate = useNavigate();
	
	const keycloak = useKeycloak();
	const userData = keycloak.keycloak.tokenParsed;

	useEffect(() => {
		get("medico/validar-login", (response:any) => {
      if("Ok" == response.data) 
        return;

		  navigate("/medico-inicial")
		});
    ;
		
	}, []);

	return (
		<Container>
			<h1>Prescrito</h1>
			<p>Bem vindo {userData?.given_name}</p>
			<Nav defaultActiveKey="/dashboard" className="flex-column">
				<Nav.Link href="/prescricao">Prescricao</Nav.Link>
				<Nav.Link href="/pacientes">Lista Pacientes</Nav.Link>
				<Nav.Link href="/paciente-editor">Novo Paciente</Nav.Link>
				<Nav.Link href="/prescricao-configuracao">Configuracao Prescricao</Nav.Link>
				<Nav.Link href="/dashboard">Dashboard</Nav.Link>
				<Nav.Link href="/secretaria-editor">Secretaria</Nav.Link>
				<Nav.Link href="/sinais-vitais">Sinais Vitais</Nav.Link>
				<Nav.Link href="/estabelecimento-lista">Estabelecimentos</Nav.Link>
				<Nav.Link href={keycloak.keycloak.createAccountUrl()}>Minha Conta</Nav.Link>
				<Nav.Link href="/medico-inicial">Medico Inicial</Nav.Link>
				<Nav.Link href="/logout">Logout</Nav.Link>
			</Nav>
		</Container>
	);
}

export default Home;
