import Form from 'react-bootstrap/Form';
import { Button,  Col, Container,    Row, Stack, } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import Pesquisa from '../componentes/Pesquisa';
import { useKeycloak } from 'keycloak-react-web';
import { useNavigate, useParams } from 'react-router-dom';
import { useSendPost, useSendPut } from '../Server';
import { useLocalCache } from '../componentes/LocalCache';

function SinaisVitaisEditor() {
	const { id } = useParams();
	const keycloak = useKeycloak();

	const navigate = useNavigate();

	const [dados, setDados] = useState<any>({});

	const sendPostF = useSendPost();
	const sendPut = useSendPut();


	function sendDados() {
		let update = id ? sendPut : sendPostF;

		let d = { ...dados };
		const path = "secretaria";
		update(
			id ? path + "/" + id : path, d,
			(response: any) => {
				console.log('data', response.data);
				navigate("/home")
			});
	}


	const [pacientes, setPacientes] = useState([]);

	const pacientesDados = useLocalCache("pacientes", "paciente/medico", setPacientes);

	useEffect(() => {
		pacientesDados();
	}, []);

	return <>
		<MenuPrincipal />
		<Container>
			<Row className='mt-5'>
				<Col>
					<h1>Sinais Vitais</h1>
				</Col>
			</Row>
			<Form className="mt-3">
				<Row>
					<Form.Group className="mb-3" >
						<Form.Label>Paciente</Form.Label>
						<Pesquisa selecao={dados.paciente} label="Nome ou CPF" placeholder='Buscar pelo nome ou CPF' itens={pacientes}
							setSelecao={(p: any) => {
								setDados({ ...dados, paciente: p })
								console.log(p);
							}}
							filterFn={(p: any, pesq: any) => (p.nome.toLowerCase().indexOf(pesq) > -1 || p.cpf === pesq)} />
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="mb-3" >
						<Form.Label>Temperatura</Form.Label>
						<Form.Control id="temperatura" type="input" placeholder="Digite a temperatura do paciente"
							value={dados.temperatura} onChange={e => setDados({ ...dados, temperatura: e.target.value })} />
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="mb-3" >
						<Form.Label>Pressão sanguiena</Form.Label>
						<Form.Control id="pressao_sistolica" type="input" placeholder="Digite a pressão sistolica"
							value={dados.pressao_sistolica} onChange={e => setDados({ ...dados, pressao_sistolica: e.target.value })} />
						<Form.Control id="pressao_diastolica" type="input" placeholder="Digite a pressão diastolica"
							value={dados.pressao_diastolica} onChange={e => setDados({ ...dados, pressao_diastolica: e.target.value })} />
					</Form.Group>
				</Row>
				<Row>
					<Form.Group className="mb-3" >
						<Form.Label>Frequencia cardiaca</Form.Label>
						<Form.Control id="frequencia_cardiaca" type="input" placeholder="Digite apenas os números do telefone"
							value={dados.frequencia_cardiaca} onChange={e => setDados({ ...dados, frequencia_cardiaca: e.target.value })} />
					</Form.Group>
				</Row>
				<Row className="justify-content-end">
					<Col sm={{ span: 4 }}>
						<Stack gap={2} direction="horizontal">
							<Form.Group className="mt-3" >
								<Button type='button' variant='primary' onClick={() => sendDados()}>Incluir</Button>
							</Form.Group>
						</Stack>
					</Col>
				</Row>


			</Form>
		</Container>
		<MenuCelular />
	</>
}
export default SinaisVitaisEditor;