import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import {redirect} from 'react-router-dom';
import Config from './Config';
import axios from 'axios';
import { useKeycloak } from 'keycloak-react-web';

function Aplicacao() {

  const [name, setName] = useState('');
  const keycloak = useKeycloak();

  function createPost() {
    axios.defaults.headers.common  = {Authorization: 'Bearer ' + keycloak.keycloak.token};
    const url = Config.get().url("aplicacao");

    axios
      .post(url, {
        nome: name,
      })
      .then((response) => {
        console.log('data', response.data);
        redirect("/aplicacao");
      });
  }

  return (
    <Container>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Nome</Form.Label>
          <Form.Control type="nome" placeholder="Nome" value={name} onChange={e => setName(e.target.value)}/>
        </Form.Group>
        <Button variant="primary" type="button" onClick={createPost}> Enviar </Button>
      </Form>
    </Container>
  );
}

export default Aplicacao;
