import { useKeycloak } from "keycloak-react-web";
import { useEffect } from "react";

const PrivateRoute = ({ children }) => {

  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    const isLoggedIn = keycloak.authenticated;
    if(initialized && !isLoggedIn){
      keycloak.login();
    }
    else {
      console.log(keycloak);
    }
  }, [initialized]);

  return isLoggedIn ? children :  null;
};

export default PrivateRoute;
