import '../index.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { useState } from 'react';
import MenuPrincipal from './MenuPrincipal';
import MenuCelular from './MenuCelular';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const [pesquisa, setPesquisa] = useState("");

  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      title: "Card Title",
      subtitle: "Card Subtitle",
      text: "Some quick example text to build on the card title and make up thebulk of the card's content.",
      link: "Card Link",
    },
    {
      id: 2,
      title: "Card Title",
      subtitle: "Card Subtitle",
      text: "Some quick example text to build on the card title and make up thebulk of the card's content.",
      link: "Card Link",
    },
    {
      id: 3,
      title: "Card Title",
      subtitle: "Card Subtitle",
      text: "Some quick example text to build on the card title and make up thebulk of the card's content.",
      link: "Card Link",
    },
    {
      id: 4,
      title: "Card Title",
      subtitle: "Card Subtitle",
      text: "Some quick example text to build on the card title and make up thebulk of the card's content.",
      link: "Card Link",
    }
  ];

  return (
    <>
      <MenuPrincipal />
      <Container>
        <Form.Group className="mt-4" controlId="formBasicEmail">
          <Row>
            <Col xs={{ span: 8 }} md={{ span: 10 }}>
              <Form.Control type="input" placeholder="Pesquisar medicamento, formula ou composto" value={pesquisa} onChange={e => setPesquisa(e.target.value)} />
            </Col>
            <Col xs={{ span: 4 }} md={{ span: 2 }}>
              <Button type="button" >Pesquisar</Button>
            </Col>
          </Row>
        </Form.Group>
        <Row className="mt-3">
          <Col>
            <Stack direction="horizontal" gap={3}>
                <Button type='button'
                  onClick={() =>  navigate("/prescricao")}
                  variant='primary'>Nova prescricão</Button>

                <Button type='button'
                  variant='outline-primary'>Novo documento</Button>
                <Button type='button'
                  variant='outline-primary'>Ultimas receitas</Button>
            </Stack>
          </Col>
        </Row>
        <Row>
          {data.filter((_, index, arr) => index < arr.length - arr.length % 2)
            .map((item) => (
              dashboardItem(item)
            ))}
        </Row>
      </Container>
      <MenuCelular />
    </>
  );

  function dashboardItem(item: any): JSX.Element {
    return <Col key={item.id} xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 3 }}>
      <Card className='mt-3'>
        <Card.Body>
          <Card.Title>{item.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{item.subtitle}</Card.Subtitle>
          <Card.Text>{item.text}</Card.Text>
          <Card.Link href="#">{item.link}</Card.Link>
        </Card.Body>
      </Card>
    </Col>;
  }
}
export default Dashboard;
