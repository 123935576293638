import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Nav, Row, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import { useKeycloak } from 'keycloak-react-web';
import { useNavigate, useParams } from 'react-router-dom';
import { useSendPost, useSendPut } from '../Server';

function MedicoCadastroInicial() {
  const { id } = useParams();
  const keycloak = useKeycloak();
  const userData = keycloak.keycloak.idTokenParsed;

  const navigate = useNavigate();
  const [dados, setDados] = useState<any>({ nome: userData?.given_name + ' ' + userData?.family_name, email: userData?.email });
  const [estabelecimento, setEstabelecimento] = useState<any>({});
  const [outros, setOutros] = useState<any>({});

  const [listaEstados, setListaEstados] = useState([
    { id: 1, nome: "Acre" },
    { id: 2, nome: "Alagoas" },
    { id: 3, nome: "Amapá" },
    { id: 4, nome: "Amazonas" },
    { id: 5, nome: "Bahia" },
    { id: 6, nome: "Ceará" },
    { id: 7, nome: "Distrito Federal" },
    { id: 8, nome: "Espírito Santo" },
    { id: 9, nome: "Goiás" },
    { id: 10, nome: "Maranhão" },
    { id: 11, nome: "Mato Grosso" },
    { id: 12, nome: "Mato Grosso do Sul" },
    { id: 13, nome: "Minas Gerais" },
    { id: 14, nome: "Pará" },
    { id: 15, nome: "Paraíba" },
    { id: 16, nome: "Paraná" },
    { id: 17, nome: "Pernambuco" },
    { id: 18, nome: "Piauí" },
    { id: 19, nome: "Rio de Janeiro" },
    { id: 20, nome: "Rio Grande do Norte" },
    { id: 21, nome: "Rio Grande do Sul" },
    { id: 22, nome: "Rondônia" },
    { id: 23, nome: "Roraima" },
    { id: 24, nome: "Santa Catarina" },
    { id: 25, nome: "São Paulo" },
    { id: 26, nome: "Sergipe" },
    { id: 27, nome: "Tocantins" }
  ]);

  const sendPostF = useSendPost();
  const sendPut = useSendPut();

  function sendDados() {
    let d = { medico: { ...dados }, estabelecimento: { ...estabelecimento }, ...outros};

    const path = "medico/create-inicial";
    sendPostF(path, d,
      (response: any) => {
        console.log('data', response.data);
        navigate("/home")
      });
  }

  return <>
    <MenuPrincipal />
    <Container>
      <Row className='mt-5'>
        <Col>
          <h3>Médico</h3>
          <p>Informe seus dados</p>
        </Col>
      </Row>
      <Form>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Nome</Form.Label>
              <Form.Control id="nome" type="input" placeholder="Digite seu nome"
                value={dados.nome} onChange={e => setDados({ ...dados, nome: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>CPF</Form.Label>
              <Form.Control id="cpf" type="input" placeholder="Digite apenas os números do CPF"
                value={dados.cpf} onChange={e => setDados({ ...dados, cpf: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>E-mail</Form.Label>
              <Form.Control id="email" type="input" placeholder="Digite o seu e-mail"
                value={dados.email} onChange={e => setDados({ ...dados, email: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Telefone</Form.Label>
              <Form.Control id="telefone" type="input" placeholder="Digite somente numeros do seu Telefone"
                value={dados.telefone} onChange={e => setDados({ ...dados, telefone: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Número CRM</Form.Label>
              <Form.Control id="numrCrm" type="input" placeholder="Digite o seu CRM"
                value={dados.numrCrm} onChange={e => setDados({ ...dados, numrCrm: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Estado CRM</Form.Label>
              <Form.Select id="estadoCrm" aria-label="Selecione o estado do seu CRM"
                onChange={e => setDados({ ...dados, estadoCrm: e.target.value })}>
                <option>Selecione</option>
                {listaEstados.map((e) => <option selected={e.id === dados.estadoCrm} value={e.id}>{e.nome}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Row className='mt-5'>
        <Col>
          <h3>Estabelecimento</h3>
        </Col>
      </Row>
      <Form className="mt-3">
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Nome</Form.Label>
              <Form.Control id="nome" type="input" placeholder="Digite o nome do estabelecimento"
                value={estabelecimento.nome} onChange={e => setEstabelecimento({ ...estabelecimento, nome: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Telefone</Form.Label>
              <Form.Control id="telefone" type="input" placeholder="Digite apenas os números do telefone"
                value={estabelecimento.telefone} onChange={e => setEstabelecimento({ ...estabelecimento, telefone: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>E-mail</Form.Label>
              <Form.Control id="email" type="input" placeholder="Digite o e-mail do estabelecimento"
                value={estabelecimento.email} onChange={e => setEstabelecimento({ ...estabelecimento, email: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Endereço</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Digite o endereço do estabelecimento"
                value={estabelecimento.endereco} onChange={e => setEstabelecimento({ ...estabelecimento, endereco: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Cidade</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Informe a cidade do estabelecimento"
                value={estabelecimento.cidade} onChange={e => setEstabelecimento({ ...estabelecimento, cidade: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Estado</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado"
                value={estabelecimento.estado} onChange={e => setEstabelecimento({ ...estabelecimento, estado: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col>
            <h3>Modelo prescrição</h3>
            <p>Dados para o modelo de prescrição</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Título</Form.Label>
              <Form.Control id="titulo" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado"
                value={outros.titulo} onChange={e => setOutros({ ...outros, titulo: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Subtítulo</Form.Label>
              <Form.Control id="subtitulo" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado"
                value={outros.subtitulo} onChange={e => setOutros({ ...outros, subtitulo: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Rodapé</Form.Label>
              <Form.Control id="rodape" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado"
                value={outros.rodape} onChange={e => setOutros({ ...outros, rodape: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-end mb-3">
          <Col sm={{ span: 1 }}>
            <Form.Group className="mt-3">{
              id ? <Button type='button' variant='primary' onClick={() => sendDados()}>Atualizar</Button> :
                <Button type='button' variant='primary' onClick={() => sendDados()}>Incluir</Button>
            }
            </Form.Group>
          </Col>
        </Row>

      </Form>
      <MenuCelular />
    </Container>
  </>
}
export default MedicoCadastroInicial;
