import { cpf } from 'cpf-cnpj-validator';
import * as emailValidator from 'email-validator';

/**
 * Validar CPF 
 * cpf: cpf do paciente
 * paciente: id do paciente atual
 * pacientes: lista de todos os pacientes
 * setAlerta: função para setar o alerta
 */
export function verificaCpf(valor: any, paciente: any, pacientes: any[], setAlerta: Function): boolean {
  if (pacientes.some((p: any) => p.cpf === valor && p.paciente !==paciente)) {
    setAlerta({ type: 'danger', message: 'CPF ja cadastrado em outro paciente' });
    return false;
  }

  if (!cpf.isValid(valor)) {
    setAlerta({ type: 'danger', message: 'CPF não é válido' });
    return false;
  }
  return true;
}

/**
 * Validar email
 * email: email do paciente
 * paciente: id do paciente atual
 * pacientes: lista de todos os pacientes
 * setAlerta: função para setar o alerta
 */
export function verificaEmail(email: any, paciente: any, pacientes: any[], setAlerta: Function): boolean {
  if (pacientes.some((p: any) => p.email === email && p.paciente !==paciente)) {
    setAlerta({ type: 'warning', message: 'Email ja cadastrado em outro paciente' });
    return false;
  }

  if (!emailValidator.validate(email)) {
    setAlerta({ type: 'warning', message: 'Email ja cadastrado em outro paciente' });
    return false;
  }
  return true;
}

/**
 * Validar telefone
 * telefone: telefone do paciente
 * paciente: id do paciente atual
 * pacientes: lista de todos os pacientes
 * setAlerta: função para setar o alerta
 */
export function verificaTelefone(telefone: any, paciente: any, pacientes: any[], setAlerta: Function): boolean {
  if (pacientes.some((p: any) => p.telefone === telefone && p.paciente !== paciente)) {
    setAlerta({ type: 'warning', message: 'Telefone ja cadastrado em outro paciente' });
    return false;
  }
  return true;
}