import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Nav, Row, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import Pesquisa from '../componentes/Pesquisa';
import { useKeycloak } from 'keycloak-react-web';
import { Link, redirect, useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendGetFile, useSendPost, useSendPostFile, useSendPut } from '../Server';
import Config from './Config';
import { url } from 'inspector';

function PrescricaoDownload() {
  const { id } = useParams();
  const keycloak = useKeycloak();

  const navigate = useNavigate();
  const userData = keycloak.keycloak.idTokenParsed;

  const [dados, setDados] = useState<any>({ prescricao: id });
  const [status, setStatus] = useState<any>(null);

  const [selected, setSelected] = useState("dados");

  const sendPostF = useSendPost();
  const sendPostFile = useSendPostFile();
  const sendPut = useSendPut();

  const sendGet = useSendGet();
  const sendGetFile = useSendGetFile();


  function downloadPrescricao() {
    const d = {...dados, id: id}
    sendPostFile("prescricao/download", d, (response: any) => {
      if(!response.data.size) {
        alert("Codigo invalido");
        return;
      }
      const href = URL.createObjectURL(
		new Blob([response.data], {type: 'application/pdf'}));

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'prescricao' + id + '.pdf');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }
  return <>
    <MenuPrincipal apenasLogo={true} logoLink="/"/>
    <Container>
      <Row className="mt-5">
        <Col>
          <h2>Acesso prescricão </h2>
        </Col>
      </Row>
      <Form>
        <Row>
          <Col sm={{ span: 6 }}>
            <Form.Label>Token</Form.Label>
              <Form.Control id="codigoDownload" type="input" placeholder="Digite o token"
                value={dados.token} onChange={e => setDados({ ...dados, token: e.target.value })} />
          </Col>
          <Col sm={{ span: 6 }}>
              <Form.Control id="codigoDownload" type="input" placeholder="Digite o codigo de acesso"
                value={dados.codigoDownload} onChange={e => setDados({ ...dados, codigoDownload: e.target.value })} />
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 6 }}>
            <Button type='button' variant='primary' onClick={() => downloadPrescricao()}>Fazer download</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  </>
}
export default PrescricaoDownload;
