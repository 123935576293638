
export interface PrescricaoConfiguracaoDados {
  id: string;
  versao: string;
  index: number;
  habilitarCabecalhoRodape: boolean;
  headerImgUrl: string;
  headerImgSize: number;

  cabecalhoAlinhamento: string;

  
  titulo: string;
  nomeFonte: string;
  nomeTamanho: number;
  nomeCor: string;

  subtitulo: string;
  descricaoFonte: string;
  descricaoTamanho: number;
  descricaoCor: string;

  rodape: string;
  textoFonte: string;
  textoTamanho: number;
  rodapeAlinhamento: any;


  rodapeFonte: string;
  rodapeTamanho: number;
  rodapeCor: string;

  espacamento: string,
  mostrarNomePaciente: boolean,
  mostrarNomeFabricante: boolean,
  mostartUnidades: boolean,
  separadorUnidades: string,
  mostrarData: string,

  pageHeight: number;
  pageWidth: number;
  marginRight: number;
  marginLeft: number;
  footerHeight: number;
  headerHeight: number;
  
  nomeCompleto: string;
  endereco: string;
  cidadeUf: string;
  telefone: string;
  crm: string;
  
  estabelecimento:string;
}

export const RECEITA_CONFIGURACAO_EXEMPLO: PrescricaoConfiguracaoDados = {
  id: "",
  versao: "",
  index: -1,
  rodape: "Clinica Central  - R. Dr. João de Abreu, 300 Sala 1005\nConsolação - São Paulo - SP - CEP: 01311-000",
  headerImgSize: 2,
  habilitarCabecalhoRodape: true,

  cabecalhoAlinhamento: 'right',

  titulo: "Dr. Jorge de Abreu Rodrigues",

  subtitulo: "Clinico Geral",
  descricaoFonte: 'Helvetica',
  descricaoTamanho: 10,
  descricaoCor: "#000000",

  nomeFonte: 'Helvetica',
  nomeTamanho: 14,
  nomeCor: "#000000",

  rodapeFonte: 'Helvetica',
  rodapeTamanho: 10,
  rodapeCor: "#000000",
  rodapeAlinhamento: 'right',

  textoFonte: 'Helvetica',
  textoTamanho: 10,
  espacamento: "medio",
  mostartUnidades: true,
  separadorUnidades: "dotted",
  mostrarNomePaciente: true,
  mostrarNomeFabricante: true,
  mostrarData: 'topo',

  pageWidth: 14.8,
  pageHeight: 21.0,
  footerHeight: 2.1,
  headerImgUrl: "uploads/test-logo.png",
  //headerImg: "uploads/horiz-test-logo.jpg",
  headerHeight: 3.5,
  marginLeft: 1.5,
  marginRight: 1.4,
  
  nomeCompleto: "Jorge de Abreu Rodrigues",
  endereco: "Rua das Azaleias 320, Qd. 4 Lt. 1 Ed. Florais Sl. 801",
  cidadeUf: "Guarulhos - SP",
  telefone: "11 8123-4312",
  crm: "564312-SP", 
  
  estabelecimento:""
}

