import Form from 'react-bootstrap/Form';
import { Accordion, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, Dropdown, DropdownButton, FormGroup, ListGroup, Nav, Row, Stack, Table, Toast, ToastContainer } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useRef, useState } from 'react';
import { TextCenter, TextLeft, TextRight } from 'react-bootstrap-icons';
import PrescricaoConfiguracaoExemplo from './PrescricaoConfiguracaoExemplo';
import { PrescricaoConfiguracaoDados, RECEITA_CONFIGURACAO_EXEMPLO } from './PrescricaoConfiguracaoDados';
import axios from 'axios';
import Config from './Config';
import { useKeycloak } from 'keycloak-react-web';
import { useSendGet, useSendGetFile, useSendPost } from '../Server';
import PrescricaoConfiguracaoEspecialExemplo from './PrescricaoConfiguracaoEspecialExemplo';
import { useNavigate } from 'react-router-dom';
import { useAlerta } from '../componentes/Alerta';

const fontes = [
  { nome: 'Helvetica' },
  { nome: 'Times New Roman' },
  { nome: 'Courier New' }
];

const tamanhos = [7, 8, 9, 10, 11, 12, 14, 16, 18];


function PrescricaoConfiguracao() {

  const fileInput = useRef<HTMLInputElement | null>(null);
  const indexArray = [0, 1, 2, 3];
  const [data, setData] = useState(
    indexArray.map(i => {
      return { ...RECEITA_CONFIGURACAO_EXEMPLO, index: i };
    }));

  const [config, setConfig] = useState(data[0]);
  const [modelo, setModelo] = useState({ modelo: 'simples', layout: '1' });
  const keycloak = useKeycloak();
  const sendGetFile = useSendGetFile();
  const sendGet = useSendGet();
  const sendPost = useSendPost();
  
  
  const [estabelecimentos, setEstabelecimentos] = useState([]);

  const currentConfig = (parseInt(modelo.layout) - 1);

  useEffect(() => {
    setData(data.map((d: any) => d.index == config.index ? config : d));
    const currentConfig = (parseInt(modelo.layout) - 1);

    console.log(data);
    console.log(currentConfig); 


    let current = data[currentConfig];
    if(config.index == currentConfig) {
      return;
    }

    setConfig({ ...current });
    let file = fileInput.current;
    if (file !== null) {
      //file.value = current.headerImgUrl || "";
    }
  }, [modelo]);
  
  let login: any = null;

  const loadConfig = () => {
    if (login) return;
    login = true;

    sendGet("modelo_prescricao/login", (response: any) => {
      let indexMap = response.data.reduce((res: any, d: any) => {
        res[d.index] = d; return res;
      }, []);

      let c = indexArray.map(i => indexMap[i] || { ...RECEITA_CONFIGURACAO_EXEMPLO, index: i });
      setData(c);

      let data = indexMap[currentConfig];
      console.log(indexMap);

      
      data.headerImgUrl = null;
      setConfig(data);

      sendGetFile("modelo_prescricao/imagem/" + data.id, (response: any) => {
        setConfig({ ...data, headerImgUrl: URL.createObjectURL(response.data) });
      });
	  
	  sendGet("modelo_prescricao/estabelecimentos", (response: any) => { 
		setEstabelecimentos(response.data);		
      });

      setTimeout(() => {
        let c: any = indexArray.filter(i => i >= 0).map(i => {
          sendGetFile("modelo_prescricao/imagem/" + indexMap[i].id, 
            (response: any) => {
            indexMap[i].headerImgUrl = URL.createObjectURL(response.data);
            setData(indexMap);
          },
          () => {
            indexMap[i].headerImgUrl = RECEITA_CONFIGURACAO_EXEMPLO.headerImgUrl;
          });
        });
      }, 0);
      login = null;
	  
	  
    });
  }

  useEffect(() => {
    loadConfig();
  }, []);

  let upload: any = null;

  function sendUpload() {
    if (upload) return;
    upload = true;

    axios.defaults.headers.common = { Authorization: 'Bearer ' + keycloak.keycloak.token };
    const url = Config.get().url("modelo_prescricao/upload");


    console.log(headerImg);

    const formdata = new FormData();
    if (headerImg) {
      Object.values(headerImg)
        .map((h: any, idx: number) => h && formdata.append("files", h, idx + "_" + h.name));
    }

    let da = data.map((d: any) => d.index == config.index ? config : d)
      .map((d: any) => {
      return {
        ...d,
        nomeCor: ajusteCor(d.nomeCor),
        descricaoCor: ajusteCor(d.descricaoCor),
        rodapeCor: ajusteCor(d.rodapeCor)
      };
    });


    formdata.append("dados", new Blob([JSON.stringify(da)],
      { type: 'application/json' }));


    axios
      .post(url, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log(response);
        upload = null
        loadConfig();
        updateMessagem({header:"Salvar", body:"Modelos salvos com sucesso"});
      });
  }

  function selecaoFonte(prefixo: string) {
    let fonte = (config as any)[prefixo + 'Fonte'];
    let tamanho = (config as any)[prefixo + 'Tamanho'];

    return <Row className="mt-2">
        <DropdownButton variant="secondary" title={fonte} style={{ fontFamily: fonte }}>
          {fontes.map((f) => <Dropdown.Item onClick={() => {
            let c = { ...config };
            (c as any)[prefixo + 'Fonte'] = f.nome;
            setConfig(c);
          }} style={{ fontFamily: f.nome }}>{f.nome}</Dropdown.Item>)}
        </DropdownButton>
    </Row>;
  }

  function selecaoItem(prefixo: string, itens: any[], valuefn: Function, labelfn: Function, selectFn:Function = (i:any, config:any) => config) {
	console.log(config);
	let sel = itens.filter((i) => valuefn(i) === (config as any)[prefixo]); 
    return <Row className="mt-1">
        <DropdownButton variant="secondary" title={sel[0] && labelfn(sel[0]) || "Selecione..."}>
          {itens.map((i) => <Dropdown.Item onClick={() => {
            let c = { ...config };
            (c as any)[prefixo] = valuefn(i);
            setConfig(selectFn(i, c));
			
          }}>{labelfn(i)}</Dropdown.Item>)}
        </DropdownButton>
    </Row>;
  }

  function selecaoFonteTam(prefixo: string) {
    let fonte = (config as any)[prefixo + 'Fonte'];
    let tamanho = (config as any)[prefixo + 'Tamanho'];

    return <Row className="mt-2">
      <Stack direction="horizontal" gap={1}>
        <DropdownButton variant="secondary" title={fonte} style={{ fontFamily: fonte }}>
          {fontes.map((f) => <Dropdown.Item onClick={() => {
            let c = { ...config };
            (c as any)[prefixo + 'Fonte'] = f.nome;
            setConfig(c);
          }} style={{ fontFamily: f.nome }}>{f.nome}</Dropdown.Item>)}
        </DropdownButton>
        <DropdownButton variant="secondary" title={tamanho}>          {tamanhos.map((t) => <Dropdown.Item onClick={() => {
          let c = { ...config };
          (c as any)[prefixo + 'Tamanho'] = t;
          setConfig(c);
        }}>{t}</Dropdown.Item>)}
        </DropdownButton>
      </Stack>
    </Row>;
  }

  function selecaoFonteCor(prefixo: string) {
    let fonte = (config as any)[prefixo + 'Fonte'];
    let tamanho = (config as any)[prefixo + 'Tamanho'];

    return <Row className="mt-2">
      <Stack direction="horizontal" gap={1}>
        <DropdownButton variant="secondary" title={fonte} style={{ fontFamily: fonte }}>
          {fontes.map((f) => <Dropdown.Item onClick={() => {
            let c = { ...config };
            (c as any)[prefixo + 'Fonte'] = f.nome;
            setConfig(c);
          }} style={{ fontFamily: f.nome }}>{f.nome}</Dropdown.Item>)}
        </DropdownButton>
        <DropdownButton variant="secondary" title={tamanho}>
          {tamanhos.map((t) => <Dropdown.Item onClick={() => {
            let c = { ...config };
            (c as any)[prefixo + 'Tamanho'] = t;
            setConfig(c);
          }}>{t}</Dropdown.Item>)}
        </DropdownButton>
        <Form.Control
          type="color"
          value={(config as any)[prefixo + 'Cor']}
          defaultValue="#999999"
          onChange={(e) => {
            let c = { ...config };
            (c as any)[prefixo + 'Cor'] = ajusteCor(e.target.value);
            setConfig(c);
          }}
          title="Selecione a cor" />
      </Stack>
    </Row>;
  }

  function ajusteCor(c: string) {
    return (c == null) ? '#000000' : c;
  }

  function selecaoAlinhamento(prefixo: any) {
    let selec = (config as any)[prefixo + 'Alinhamento'];

    let options = [
      { name: 'left', content: <TextLeft /> },
      { name: 'center', content: <TextCenter /> },
      { name: 'right', content: <TextRight /> }
    ];

    return <Row className="mt-1">
      <ButtonGroup>
        {options.map((o) => <Button variant={o.name == selec ? "primary" : "secondary"} onClick={() => {
          let c = { ...config };
          (c as any)[prefixo + 'Alinhamento'] = o.name;
          setConfig(c);
        }}>{o.content}</Button>)}
      </ButtonGroup>
    </Row>;
  }

  const [headerImg, setHeaderImg] = useState<any[]>([0, 1, 2, 3, 4, 5, 6, 7].map(() => undefined));

  const handleFileSelect = (event: any) => {
    setConfig({ ...config, headerImgUrl: URL.createObjectURL(event.target.files[0]) });
    var newValue = { ...headerImg };
    newValue[currentConfig] = event.target.files[0];
    setHeaderImg(newValue);
    event.target.value = null;
  }

  function selecaoModelo(chave: any, opcoes: any[]) {
    return selecaoButtonGroup(chave, opcoes, modelo, setModelo);
  }

  function selecaoOpcoes(chave: any, opcoes: any[]) {
    return selecaoButtonGroup(chave, opcoes, config, setConfig);
  }

  function selecaoButtonGroup(chave: any, opcoes: any[], config: any, setConfig: Function) {
    let selec = (config as any)[chave];
    return <Row className="mt-1">
      <ButtonGroup>
        {opcoes.map((o) => <Button variant={o.nome == selec ? "primary" : "secondary"} onClick={() => {
          let c = { ...config };
          (c as any)[chave] = o.nome;
          setConfig(c);
        }}>{o.label}</Button>)}
      </ButtonGroup>
    </Row>;
  }

  const navigate = useNavigate();
 
  let usarTemaPost = false;

  function usarTema(): void {
    if(usarTemaPost) return;
    usarTemaPost = true;

    sendPost("modelo_prescricao/usar_tema", { id: config.id }, () => {
      usarTemaPost = false;
      navigate("/prescricao");
    });
  }

  const [mensagem, setMensagem] = useState<any>(null);

  const updateMessagem = (m: any) => {
    setMensagem(m);
    setTimeout(() => setMensagem(null), 3000);
  }

  const updateEstabelecimento = (e:any, config:any) => ({...config, endereco: e.endereco, cidadeUf: e.cidade + "/"+ e.estado, })

  return <>
    <MenuPrincipal />
    <ToastContainer position="top-end"  style={{zIndex: 1}}>
      <Toast bg="ligth" show={mensagem != null} onClose={() => setMensagem(null) } 
        style={{ position: 'relative' }}>
        <Toast.Header>
          <strong className="me-auto">{mensagem?.header}</strong>
        </Toast.Header>
        <Toast.Body>{mensagem?.body}</Toast.Body>
      </Toast>
    </ToastContainer>

    <Container>
      <Form>
        <Row className="mt-5">
          <Col sm={{ span: 6 }}>
            <Row>
              <h2>Opções Receituário</h2>
            </Row>
          </Col>
          <Col sm={{ span: 6 }}>
            <Row className="justify-content-end">
              <Col md={{ span: 6 }}>
                <Stack direction="horizontal" gap={2}>
                  <Button variant="secondary">Cancelar</Button>
                  <Button variant="primary" onClick={() => sendUpload()}>Salvar</Button>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6 }} lg={{ span: 4 }}>
            <Row>
              <Form.Group>
                <Form.Label column>Tema</Form.Label>
                <Row>
                  <Col sm={{ span: 6 }}>
                    <ButtonToolbar>
                      <ButtonGroup>
                        {selecaoModelo('layout', [
                          { nome: '1', label: '1' },
                          { nome: '2', label: '2' },
                          { nome: '3', label: '3' },
                          { nome: '4', label: '4' }
                        ])}
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                  <Col  sm={{ span: 6 }}>
                    <Button variant="primary" onClick={() => usarTema()}>Usar tema</Button>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
            <Accordion defaultActiveKey="0" className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Cabeçalho e Rodapé
                </Accordion.Header>
                <Accordion.Body>
                  <Form.Label className="mt-4">Selecione um estabelecimento</Form.Label>
				  {selecaoItem('estabelecimento', estabelecimentos, (i:any) => i.estabelecimento, (i:any)=> i.nome, updateEstabelecimento) }
				
				
                  <Form.Check className="mt-4" type="switch" label="Habilitar Cabeçalho e Rodapé" checked={config.habilitarCabecalhoRodape} onChange={(e) => setConfig({ ...config, habilitarCabecalhoRodape: e.target.checked })} />

                  <Form.Label className="mt-4">Arquivo Logo</Form.Label>

                  <img src={config.headerImgUrl} width="100%" onClick={() => fileInput.current?.click()} style={{ cursor: 'pointer' }} title="Clique para selecionar a imagem" />
                  <Form.Control type="file" ref={fileInput} onChange={handleFileSelect} className="mt-2"
                    style={{ display: config.headerImgUrl == RECEITA_CONFIGURACAO_EXEMPLO.headerImgUrl ? 'block' : 'none' }} />



                  <Form.Label className="mt-4" value="1">Tamanho Logo</Form.Label>
                  <Form.Range value={config.headerImgSize * 20} onChange={(e) => setConfig({ ...config, headerImgSize: e.target.valueAsNumber / 20 })} />

                  <Form.Label className="mt-4">Alinhamento cabeçalho</Form.Label>
                  {selecaoAlinhamento("cabecalho")}

                  <Form.Label className="mt-4">Título</Form.Label>
                  <Form.Control value={config.titulo} onChange={(e) => setConfig({ ...config, titulo: e.target.value })} />

                  {selecaoFonteCor("nome")}

                  <Form.Label className="mt-4">Subtítulo</Form.Label>
                  <Form.Control value={config.subtitulo} onChange={(e) => setConfig({ ...config, subtitulo: e.target.value })} />

                  {selecaoFonteCor("descricao")}


                  <Form.Label className="mt-4">Rodapé</Form.Label>
                  <Form.Control as="textarea" rows={3} value={config.rodape} onChange={(e) => setConfig({ ...config, rodape: e.target.value })} />
                  {selecaoAlinhamento("rodape")}
                  {selecaoFonteCor("rodape")}

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Layout</Accordion.Header>
                <Accordion.Body>

                  <Form.Label className="mt-4">Texto</Form.Label>
                  {selecaoFonte('texto')}

                  <Form.Label className="mt-4">Espaçamento</Form.Label>
                  {selecaoOpcoes('espacamento', [
                    { nome: 'pequeno', label: 'Pequeno' },
                    { nome: 'medio', label: 'Médio' },
                    { nome: 'grande', label: 'Grande' },
                  ])}


                  <Form.Check className="mt-4" type="switch" label="Mostrar nome do fabricante"
                    checked={config.mostrarNomeFabricante}
                    onChange={(e) => {
                      setConfig({ ...config, mostrarNomeFabricante: e.target.checked });
                    }} />


                  <Form.Label className="mt-4">Mostrar data</Form.Label>
                  {selecaoOpcoes('mostrarData', [
                    { nome: 'topo', label: 'Topo' },
                    { nome: 'rodape', label: 'Rodapé' },
                    { nome: 'naoMostrar', label: 'Não Mostrar' },
                  ])}

                  <Form.Label className="mt-4">Separador de unidades</Form.Label>
                  {selecaoOpcoes('separadorUnidades', [
                    { nome: 'dotted', label: 'Pontilhado' },
                    { nome: 'solid', label: 'Risco' },
                    { nome: 'none', label: 'Nenhum' },
                  ])}

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Identificação do Emitente</Accordion.Header>
                <Accordion.Body>

                  <Form.Label className="mt-4">Nome</Form.Label>
                  <Form.Control value={config.nomeCompleto} onChange={(e) => setConfig({ ...config, nomeCompleto: e.target.value })} />

                  <Form.Label className="mt-4">Endereço</Form.Label>
                  <Form.Control value={config.endereco} onChange={(e) => setConfig({ ...config, endereco: e.target.value })} />
                  
                  <Form.Label className="mt-4">CRM</Form.Label>
                  <Form.Control value={config.crm} onChange={(e) => setConfig({ ...config, crm: e.target.value })} />

                  <Form.Label className="mt-4">Cidade - UF</Form.Label>
                  <Form.Control value={config.cidadeUf} onChange={(e) => setConfig({ ...config, cidadeUf: e.target.value })} />

                  <Form.Label className="mt-4">Telefone</Form.Label>
                  <Form.Control value={config.telefone} onChange={(e) => setConfig({ ...config, telefone: e.target.value })} />

                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Col>
          <Col md={{ span: 6 }} className="mb-4">
            <ButtonGroup className="mt-5">
              {selecaoModelo('modelo', [
                { nome: 'simples', label: 'Simples' },
                { nome: 'especial', label: 'Especial' },
              ])}
            </ButtonGroup>
            <Card className="mt-2">
              <Card.Body style={{ padding: 0 }}>
                  <PrescricaoConfiguracaoExemplo display={modelo.modelo == 'simples'} config={config} setConfig={setConfig} /> 
                  <PrescricaoConfiguracaoEspecialExemplo display={modelo.modelo == 'especial'} config={config} setConfig={setConfig} />
             </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
    <MenuCelular />
  </>
}
export default PrescricaoConfiguracao;


