import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row, Stack, } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { ChangeEvent, useEffect, useState } from 'react';
import { useKeycloak } from 'keycloak-react-web';
import { useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendPost, useSendPut } from '../Server';
import { useLocalCacheUpdate } from '../componentes/LocalCache';

function EstabelecimentoEditor() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [dados, setDados] = useState<any>({});

  const sendPostF = useSendPost();
  const sendPut = useSendPut();

  const sendGet = useSendGet();
  const cacheUpdate = useLocalCacheUpdate("estabelecimentos");

  function sendDados() {
    let update = id ? sendPut : sendPostF;

    let d = { ...dados };
    const path = "estabelecimento";
    update(
      id ? path + "/" + id : path, d,
      (response: any) => {
        console.log('data', response.data);
        cacheUpdate((d: any) => d.estabelecimento !== response.data.estabelecimento, response.data);
        navigate("/estabelecimento-lista")
      });
  }

  useEffect(() => {
    if (id) {
      sendGet("estabelecimento/" + id, (response: any) => {
        setDados(response.data);
      });
    }
  }, [id]);

  return <>
    <MenuPrincipal />
    <Container>
      <Row className='mt-5'>
        <Col>
          <h1>Estabelecimento</h1>
        </Col>
      </Row>
      <Form className="mt-3">
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Nome</Form.Label>
              <Form.Control id="nome" type="input" placeholder="Digite o nome do estabelecimento"
                value={dados.nome} onChange={e => setDados({ ...dados, nome: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Telefone</Form.Label>
              <Form.Control id="telefone" type="input" placeholder="Digite apenas os números do telefone"
                value={dados.telefone} onChange={e => setDados({ ...dados, telefone: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>E-mail</Form.Label>
              <Form.Control id="email" type="input" placeholder="Digite o e-mail do estabelecimento"
                value={dados.email} onChange={e => setDados({ ...dados, email: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Endereço</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Digite o endereço do estabelecimento"
                value={dados.endereco} onChange={e => setDados({ ...dados, endereco: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Cidade</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Informe a cidade do estabelecimento"
                value={dados.cidade} onChange={e => setDados({ ...dados, cidade: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" >
              <Form.Label>Estado</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Informe o estado onde o estabelecimento esta localizado"
                value={dados.estado} onChange={e => setDados({ ...dados, estado: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm={{ span: 4 }}>
            <Stack gap={2} direction="horizontal">
              <Form.Group className="mt-3" >{
                id ? <Button type='button' variant='primary' onClick={() => sendDados()}>Atualizar</Button> :
                  <Button type='button' variant='primary' onClick={() => sendDados()}>Incluir</Button>
              }
              </Form.Group>
            </Stack>
          </Col>
        </Row>
      </Form>
    </Container>
    <MenuCelular />
  </>
}
export default EstabelecimentoEditor;
